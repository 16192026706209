import { Box, Typography } from '@mui/material';
import React from 'react';
import { indicatorsConfiguration } from '../settings';
import OutlookLabel from './OutlookLabel';

const IndicatorTitle = ({ id, children, sx = {}, ...rest }) => (
  <Typography
    variant="h4"
    id={id}
    sx={{
      marginTop: '-50px',
      paddingTop: '70px',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap-reverse',
      '@media print': {
        paddingTop: '5px',
        marginTop: 0,
        fontSize: '1.2em',
      },
      ...sx,
    }}
    gutterBottom
    {...rest}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {indicatorsConfiguration[id]?.iconName && (
        <Box
          component="img"
          sx={{
            height: '1.2em',
            pr: 1,
          }}
          alt=""
          src={`/pictogrammes/${indicatorsConfiguration[id]?.iconName}`}
        />
      )}

      {children}
    </Box>

    <OutlookLabel
      sx={{
        marginLeft: 'auto',
        my: 1,
        fontSize: {
          default: '0.75em',
          sm: '1em',
        },
      }}
    />
  </Typography>
);

export default React.memo(IndicatorTitle);
