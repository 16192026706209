import { Box, Divider } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import IndicatorTitle from './IndicatorTitle';
import IndicatorChart from './IndicatorChart';
import IndicatorTopo from './IndicatorTopo';
import IndicatorDescription from './IndicatorDescription';
import Risks from './Risks';
import { getIndicatorData, getSelectedSeason } from '../helpers';
import { getSeasons } from '../chartsHelpers';

const Indicator = ({
  entity = {},
  entity: { type, indicateurs } = {},
  indicatorKey,
  display,
  title,
  screenType,
  description,
}) => {
  const outlookIndex = useSelector(state => state.outlook.index);

  const data = getIndicatorData(indicateurs, indicatorKey)[outlookIndex];
  const seasons = data ? getSeasons(data) : [];
  const selectedSeason = getSelectedSeason(seasons, screenType === 'smallScreen');

  return (
    <Box
      key={indicatorKey}
      sx={{
        pageBreakInside: 'avoid',
        // pageBreakAfter: 'always',
        // Currently, it's way more readable if each page has only one indicator
        display: display ? 'block' : 'none',
        mb: 2,
      }}
    >
      <Divider sx={{ mb: 2, mt: 4, '@media print': { mb: 0.5, mt: 0 } }} />

      <IndicatorTitle id={indicatorKey}>{title}</IndicatorTitle>

      {data && (
        <IndicatorChart
          entityData={data}
          indicatorKey={indicatorKey}
          seasons={seasons}
          selectedSeason={selectedSeason}
          title={title}
          screenType={screenType}
        />
      )}

      <IndicatorTopo
        entity={entity}
        indicatorKey={indicatorKey}
      />

      <IndicatorDescription
        description={description}
        type={type}
      />

      <Risks
        entity={entity}
        indicatorKey={indicatorKey}
      />
    </Box>
  );
};

export default Indicator;
