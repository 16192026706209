import React from 'react';
import { Box } from '@mui/material';

import ChartContainer from './ChartContainer';

const IndicatorChart = ({
  screenType,
  ...rest
}) => (
  <>
    <Box sx={{ displayPrint: 'none' }}>
      <ChartContainer
        isSingleSeasonRow={screenType === 'smallScreen'}
        screenType={screenType}
        {...rest}
      />
    </Box>

    <Box sx={{ displayPrint: 'block', display: 'none' }}>
      <ChartContainer
        isPrint
        {...rest}
      />
    </Box>
  </>
);

export default React.memo(IndicatorChart);
