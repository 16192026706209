import * as React from 'react';

import { Dialog, Divider, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import NestedList from './NestedList';

const ContentPageNavigation = ({
  pageContentHierarchy,
  itemsStatus,
  itemsStates,
  toggleItem,
  scrollTo,
  children,
  entityType,
  handleTableOfContentDialogClose,
  openTableOfContentDialog,
  handleSearchDialogClose,
  openSearchDialog,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const tableOfContent = (
    <NestedList
      groups={pageContentHierarchy}
      itemsStatus={itemsStatus}
      itemsStates={itemsStates}
      toggleItem={toggleItem}
      scrollTo={scrollTo}
      entityType={entityType}
    />
  );

  return (
    <div className="hidden-print">
      {!isSmallScreen && tableOfContent}
      {isSmallScreen && (
        <>
          <Dialog
            disableRestoreFocus
            onClose={handleTableOfContentDialogClose}
            open={openTableOfContentDialog}
            sx={{ displayPrint: 'none' }}
          >
            {tableOfContent}
            <Divider />
            <Button variant="contained" sx={{ m: 2 }} onClick={handleTableOfContentDialogClose}>Valider la sélection</Button>
          </Dialog>
          <Dialog
            disableRestoreFocus
            fullWidth
            onClose={handleSearchDialogClose}
            open={openSearchDialog}
          >
            {children}
          </Dialog>
        </>
      )}
    </div>
  );
};

export default ContentPageNavigation;
