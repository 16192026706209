import React from 'react';
import { Box } from '@mui/material';

import ReadingGuide from './ReadingGuide';
import EntityContentTitle from './EntityContentTitle';
import IndicatorGroup from './IndicatorGroup';
import EntityCharacteristics from './EntityCharacteristics';

const EntityPageContent = ({
  entity = {},
  contentHierarchy,
  itemsState,
  screenType,
}) => contentHierarchy.map(({ contentTitle: title, key, items = [] }) => {
  const { nom, code, type } = entity;

  if (key === 'caracteristiques') {
    return (
      <Box key={key} sx={{ pageBreakInside: 'avoid' }}>
        <EntityContentTitle
          id={key}
          title={title}
          type={type}
          sx={{ pageBreakBefore: 'unset' }}
        />

        <EntityCharacteristics entity={entity} />
      </Box>
    );
  }

  if (key === 'comprendre') {
    return (
      <Box key={key} sx={{ pageBreakInside: 'unset' }}>
        <EntityContentTitle
          id={key}
          title={title}
          type={type}
          sx={{ pageBreakBefore: 'unset' }}
        />

        <ReadingGuide entityType={type} code={code} name={nom} />
      </Box>
    );
  }

  return (
    <IndicatorGroup
      key={key}
      entity={entity}
      itemsState={itemsState}
      screenType={screenType}
      groupTitle={title}
      items={items}
      anchor={key}
    />
  );
});

export default EntityPageContent;
