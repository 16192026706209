import React from 'react';
import { Box, Stack, Typography, useTheme, Link } from '@mui/material';

import useTokenReplacement from '../hooks/useTokenReplacement';
import { homePage, print, printLastPage } from '../settings';
import homeBackgroundBlue from '../images/home_background_blue.png';
import climadiagLogo from '../images/logo_light_climadiag.png';
import meteoFranceLogo from '../images/logo_meteo_france.svg';
import franceLogo from '../images/logo_republique_francaise.svg';
import drias from '../images/pdf_export/drias.png';
import climathd from '../images/pdf_export/climathd.png';
import icu from '../images/pdf_export/icu.jpg';
import driasFutur from '../images/pdf_export/drias-futur.jpg';
import climadiagEntreprise from '../images/pdf_export/climadiag_entreprise.png';
import francesadapte from '../images/france-s-adapte.png';
import QRCode from './QRCode';
import OutlookLabel from './OutlookLabel';

export const FirstPage = ({ nom }) => {
  const theme = useTheme();
  const replaceTokens = useTokenReplacement();

  const date = new Date()
    .toLocaleString('fr-FR', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    .replaceAll('/', '-');

  return (
    <>
      <Box
        sx={{
          marginTop: '-50px',
          display: 'none',
          displayPrint: 'block',
          width: '100%',
          maxHeight: '100vh',
          pageBreakInside: 'avoid',
        }}
      >
        <Box
          sx={{
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '0 200px 200px 0',
            borderColor: `transparent ${theme.palette.secondary.main} transparent transparent`,
            float: 'right',
          }}
        />
        <Box component="img" width="100%" mt={10} src={homeBackgroundBlue} />
        <Typography
          variant="h4"
          fontWeight="500"
          whiteSpace="pre-wrap"
          textTransform="uppercase"
        >
          {replaceTokens(homePage.subtitle)}
        </Typography>
        <Typography
          variant="h5"
          fontWeight="400"
          whiteSpace="pre-wrap"
        >
          {replaceTokens(homePage.printCoverSubtitle)}
          <OutlookLabel sx={{ ml: 3 }} />
        </Typography>
        <Box
          height="0"
          width="70%"
          my={3}
          borderBottom={`2px solid ${theme.palette.secondary.main}`}
        />
        <Typography variant="h4" fontWeight="600" textTransform="uppercase">
          {nom}
        </Typography>
        <Typography variant="h6" fontWeight="500">
          {date}
        </Typography>
        <Box
          sx={{
            width: 0,
            height: 0,
            marginTop: 5,
            marginBottom: 5,
            borderStyle: 'solid',
            borderWidth: '75px 0 0 75px',
            borderColor: `transparent transparent transparent ${theme.palette.secondary.main}`,
          }}
        />
        <Stack direction="row" mt={2} justifyContent="space-between">
          <Box component="img" width={180} height={60} src={climadiagLogo} />
          <Box
            component="img"
            src={francesadapte}
            sx={{
              display: 'block',
              width: 150,
              height: 53,
              aspectRatio: '1158 / 411',
            }}
          />
          <Box>
            <Box component="img" width={60} src={franceLogo} />
            <Box component="img" width={60} ml={2} src={meteoFranceLogo} />
          </Box>
        </Stack>
      </Box>

      <PageBreak />
    </>
  );
};

const imgStore = {
  drias,
  climathd,
  climadiagEntreprise,
  icu,
  driasFutur,
};

export const LastPage = () => (
  <Box
    sx={{
      display: 'none',
      displayPrint: 'block',
      width: '100%',
      pageBreakBefore: 'always',
    }}
  >
    <Typography variant="h4" gutterBottom>
      Quelques autres services climatiques de Météo-France
    </Typography>

    <Stack spacing={3.2}>
      {printLastPage.map(({ title = '', content = '', img, link }) => (
        <Stack
          key={title}
          direction="row"
          alignItems="center"
          spacing={3}
        >
          <Box
            component="img"
            src={imgStore[img] ? imgStore[img] : imgStore}
            sx={{
              flexShrink: 0,
              width: 200,
              height: 200,
              objectFit: 'contain',
            }}
          />

          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" gutterBottom>{title}</Typography>
            <Typography fontSize={11}>{content}</Typography>
            {link && (
              <Link fontSize={11} href={link}>{link}</Link>
            )}
          </Box>

          {link && (
            <QRCode link={link} sx={{ width: 120, ml: 'auto' }} />
          )}
        </Stack>
      ))}
    </Stack>
  </Box>
);

export const PageBreak = () => (
  <Box
    sx={{ display: 'none', displayPrint: 'block', pageBreakAfter: 'always' }}
  >
    &nbsp;
  </Box>
);

export const printPage = ({
  code = '',
  name = '',
  outlook,
  showPrint = () => {},
}) => {
  if (!window.print) {
    // eslint-disable-next-line no-alert
    window.alert(
      print.error,
    );
  } else {
    // clean name of commune
    const cleanName = name
      .replaceAll(' ', '_') // Replace all spaces with "_"
      .normalize('NFD') // Normalize to unicode so that accents are unique characters
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z_-])/g, ''); // Remove unique characters accents and all characters not wanted

    const titleItems = [
      'CLIMADIAG',
      code,
      cleanName,
    ];
    if (outlook) { titleItems.push(outlook); }

    document.title = titleItems.join('_');

    if (localStorage.getItem('CLIMADIAG_hide_tutorial') === 'true') {
      window.print();
    } else {
      showPrint();
    }
  }
};
