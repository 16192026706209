import React from 'react';
import { Typography } from '@mui/material';

import useTokenReplacement from '../hooks/useTokenReplacement';
import { chartLegend } from '../settings';

const TooltipContent = ({
  title,
  indicatorKey,
  indicatorValueFormating,
  indicatorTooltipTitle,
  seasonLabel,
  reference,
  min,
  mean,
  max,
}) => {
  const replaceTokens = useTokenReplacement();
  const useValueFormating = indicatorKey === 'AG2';
  const customTitle = indicatorTooltipTitle(title, seasonLabel);

  return (
    <>
      {(title !== null) && (<Typography variant="body1">{customTitle}</Typography>)}
      {(reference !== null) && (<Typography color="inherit">{`- ${replaceTokens(chartLegend.legendItems[0].label)}: ${useValueFormating ? indicatorValueFormating(reference) : reference}`}</Typography>)}
      {(max !== null) && (<Typography color="inherit">{`- ${replaceTokens(chartLegend.legendItems[1].label)}: ${useValueFormating ? indicatorValueFormating(max) : max}`}</Typography>)}
      {(mean !== null) && (<Typography color="inherit">{`- ${replaceTokens(chartLegend.legendItems[2].label)}: ${useValueFormating ? indicatorValueFormating(mean) : mean}`}</Typography>)}
      {(min !== null) && (<Typography color="inherit">{`- ${replaceTokens(chartLegend.legendItems[3].label)}: ${useValueFormating ? indicatorValueFormating(min) : min}`}</Typography>)}
    </>
  );
};

export default React.memo(TooltipContent);
