import React from 'react';
import { Typography } from '@mui/material';

import { printTextSize } from '../entityPageHelpers';
import useTokenReplacement from '../hooks/useTokenReplacement';

const IndicatorDescriptionParagraph = React.memo(({ description = [], ...props }) => {
  const { sx = {}, type, ...rest } = props;
  const replaceTokens = useTokenReplacement({ entityType: type });

  if (description instanceof Array) {
    // If description is an Array → recurse!
    return description.map(descriptionItem => (
      <IndicatorDescriptionParagraph
        key={descriptionItem}
        description={descriptionItem}
        {...props}
      />
    ));
  }

  return (
    <Typography
      gutterBottom
      key={description}
      sx={{ ...printTextSize, ...sx }}
      {...rest}
    >
      {replaceTokens(description)}
    </Typography>
  );
});

export default IndicatorDescriptionParagraph;
