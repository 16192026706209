import React from 'react';
import { Box, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import Hexagone from './Hexagone';

const outlooks = [
  {
    label: 2030,
    value: '+2°C',
  },
  {
    label: 2050,
    value: '+2,7°C',
  },
  {
    label: 2100,
    value: '+4°C',
  },
];

const OutlookLabel = ({ color = 'primary', sx = {}, ...props }) => {
  const outlookIndex = useSelector(state => state.outlook.index);
  if (!outlooks[outlookIndex]) {
    return null;
  }

  return (
    <Box
      component="span"
      sx={{
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        printColorAdjust: 'exact',
        WebkitPrintColorAdjust: 'exact',
        '@media print': {
          fontSize: '1.2em',
        },
        ...sx,
      }}
      {...props}
    >
      <Chip
        color={color}
        label={outlooks[outlookIndex].label}
        sx={{
          height: '1.7em',
          borderRadius: '0.85em',
          paddingRight: '2em',
          fontSize: '1em',
          fontWeight: 700,
        }}
      />
      <Box
        component="span"
        sx={{
          display: 'inline-block',
          verticalAlign: 'middle',
          position: 'absolute',
          top: '50%',
          right: '-0.05em',
          transform: 'translateY(-50%)',
        }}
      >
        <Hexagone sx={{ width: '2.2em', verticalAlign: 'middle' }} />
        <Box
          component="span"
          sx={{
            display: 'inline-block',
            position: 'absolute',
            top: '52%',
            transform: 'translateY(-50%)',
            right: 0,
            left: '27%',
            textAlign: 'center',
            fontSize: '0.45em',
            color: 'secondary.contrastText',
            printColorAdjust: 'exact',
            WebkitPrintColorAdjust: 'exact',
          }}
        >
          {outlooks[outlookIndex].value}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(OutlookLabel);
