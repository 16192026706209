import * as React from 'react';

import { Box, Typography } from '@mui/material';

import { seasonTextPxSizePrint } from '../settings';
import { capitalizeFirstLetter } from '../helpers';
import {
  svgText, drawLines, drawShapes,
  getLinearScale, getPointScale, getBandScale,
  xPositions,
  getSeasons,
  getIndicatorValueFormating,
  getTooltipTitle,
  circleSizeConfig,
} from '../chartsHelpers';
import HtmlTooltip from './HtmlTooltip';
import TooltipContent from './TooltipContent';

const singleSeasonKey = 'singleAxis';
const greyColor = '#d9d9d9';

/**
 * Find the reference value for a given season in the data
 * an array of object : { type: 'saisonnier', label: 'Été', ref: 8, min: 11, mean: 13, max: 14 }
 *
 * @param {array of object} data
 * @param {string} season
 * @returns {int}
 */
const getRefBySeason = (data = [], season) =>
  data.find(({ label, type_ind: type }) => label === season || type === season)?.ref;

// Build lines joining circles
const getLinesDrawingData = (indicatorData = [], seasonFormatedData = [], configChartsPoint) =>
  seasonFormatedData
    .filter(({ type }) => type !== xPositions[0])
    .map(({ x, value, type, season, axisSeason }) => {
      const refPointValue = getRefBySeason(indicatorData, season);
      return ({
        xStart: 'ref',
        yStart: refPointValue,
        xEnd: x,
        yEnd: value,
        color: configChartsPoint[type]?.color,
        lineWidth: configChartsPoint[type]?.lineWidth,
        season,
        axisSeason,
      });
    });
/**
 * Format data to be displayed in the charts
 * Add axisSeason attribute which designate on which season x scale to draw
 * - for an annual indicators, it would be annual -> 1 row
 * - for saisonnal indicators on big screen, we keep the seasons -> 4 rows
 * - for saisonnal indicators on small screen, we draw all of them on 1 row ('singleAxis')
 * @param {array of object} data
 * @param {boolean} isSingleSeasonRow
 * @returns {array of object}
 */

const formatData = (data = [], isSingleSeasonRow) =>
  data.map(({ label, ref, min, mean, max, type_ind: typeInd }) => {
    const season = label || typeInd;
    const axisSeason = isSingleSeasonRow
      ? singleSeasonKey
      : season;

    return ({
      seasonLabel: season,
      seasonData: [
        { x: 'ref', type: 'ref', value: ref, season, axisSeason },
        { x: 'target', type: 'min', value: min, season, axisSeason },
        { x: 'target', type: 'max', value: max, season, axisSeason },
        { x: 'target', type: 'mean', value: mean, season, axisSeason },
      ].filter(({ value }) => value !== null),
    });
  });

const Chart = ({
  indicatorKey = 'G1',
  configChartsPoint = {},
  width = 250,
  height = 350,
  data = [],
  // data: multiData = [],
  selectedSeason = '',
  isSingleSeasonRow = false,
  title,
  isPrint = false,
  screenType = 'largeScreen',
}) => {
  const indicatorValueFormating = getIndicatorValueFormating(indicatorKey);
  const indicatorTooltipTitle = getTooltipTitle(indicatorKey);
  const useValueFormatingInData = indicatorKey !== 'AG2';
  const useValueFormatingonShapeText = indicatorKey === 'AG2';
  // eslint-disable-next-line camelcase
  const formatedData = data.map(({ label, ref, min, mean, max, type_ind }) => ({
    label,
    type_ind,
    ref: useValueFormatingInData ? indicatorValueFormating(ref) : ref,
    min: useValueFormatingInData ? indicatorValueFormating(min) : min,
    mean: useValueFormatingInData ? indicatorValueFormating(mean) : mean,
    max: useValueFormatingInData ? indicatorValueFormating(max) : max,
  }));

  const flatedData = formatData(formatedData, isSingleSeasonRow);

  const ySerie = flatedData.map(({ seasonData }) => seasonData).flat().map(({ value }) => value);
  const yMin = Math.min(...ySerie);
  const yMax = Math.max(...ySerie);
  let hMin = yMin;
  let hMax = yMax;

  const seasons = isSingleSeasonRow
    ? [singleSeasonKey]
    : getSeasons(data);

  // Display only actual seasons names
  // ----------------------------------
  // if we display all seasons (isSingleSeasonRow is false)
  // filter out annuel type (only one chart)
  let displaySeasons = seasons
    .filter(season => season !== 'annuel')
    .map(season => ({
      position: season,
      label: capitalizeFirstLetter(season),
    }));

  // if we display only one season (isSingleSeasonRow is true and selectedSeason is not empty)
  if (isSingleSeasonRow) {
    displaySeasons = selectedSeason ? [capitalizeFirstLetter(selectedSeason)] : [];
  }

  // vertical pixel margin is as the circle radius
  // because circle are drawn from their center
  // we want to display all the circle
  // and values text that can be above and below the highest/lower circle
  const isAnnualIndicator = !isSingleSeasonRow && seasons.length === 1;
  // annual indicator (or season in full page for mobile) have larger text
  const circleTextMarginRatio = (isAnnualIndicator || isSingleSeasonRow) ? 1.7 : 1.4;
  // Reduce margin on export
  const circleSize = (isPrint && isAnnualIndicator)
    ? circleSizeConfig.season
    : circleSizeConfig.annual;
  const yPxMargin = circleSize * circleTextMarginRatio;
  const seasonTextMarginPx = isPrint ? seasonTextPxSizePrint : 25;
  const seasonTextMargin = (!isSingleSeasonRow && displaySeasons.length > 1)
    ? seasonTextMarginPx
    : 0;
  const yMarginTop = yPxMargin + seasonTextMargin;
  const yMarginBottom = yPxMargin;

  /*
    Scales adaptation
  */
  // For an annual indicator, we adapt the scale to include 10% variation from the mean
  // - Hbas= min[min(y1…y4), 0,95 x moyenne(y1…y4)]
  // - Hhaut= max[max(y1…y4), 1,05 x moyenne(y1…y4)]
  if (isAnnualIndicator) {
    const average = ySerie.reduce((a, b) => a + b, 0) / ySerie.length;
    hMin = Math.min(Math.min(...ySerie), 0.95 * average);
    hMax = Math.max(Math.max(...ySerie), 1.05 * average);
  }

  // For a seasonnal indicator in small screen (isSingleSeasonRow)
  // we want to have a dedicated yScale for each season that represent
  // [ (yMin + yMax) / 2 - 0.5 * dyMax,  (yMin + yMax) / 2 + 0.5 * dyMax ]
  // dyMax being the maximal y range among season data
  const yScalesBySeasons = {};
  if (isSingleSeasonRow && selectedSeason) {
    const seasonsRanges = formatedData.map(({ ref, min, max }) =>
      Math.max(ref, min, max) - Math.min(ref, min, max));
    const dyMax = Math.max(...seasonsRanges);

    formatedData.forEach(({ label, ref, min, max }) => {
      const minVal = Math.min(ref, min, max);
      const maxVal = Math.max(ref, min, max);
      const yRange = [
        (minVal + maxVal) / 2 - 0.5 * dyMax,
        (minVal + maxVal) / 2 + 0.5 * dyMax,
      ];

      yScalesBySeasons[label] = getLinearScale(
        [height - yMarginBottom, yMarginTop],
        [yRange[0], yRange[1]],
      );
    });
  }

  const paddingInner = 0.4;
  let paddingOuter = 0.1;
  if (isPrint || screenType === 'mediumScreen') { paddingOuter = 0.2; }
  const xSeason = getBandScale([0, width], seasons, paddingInner, paddingOuter);
  const yScale = getLinearScale([height - yMarginBottom, yMarginTop], [hMin, hMax]);
  const xPoint = getPointScale([0, xSeason.bandwidth()], xPositions);
  const xScale = (x, season) => xSeason(season) + xPoint(x);

  // For each season, create circles+text and lines to be drawn
  const drawingData = flatedData.map(({ seasonLabel, seasonData }) => {
    // Define the yScale to use to draw
    const seasonYscale = !isSingleSeasonRow
      ? yScale
      : yScalesBySeasons[seasonLabel] || yScale;

    const seasonLines = getLinesDrawingData(formatedData, seasonData, configChartsPoint);
    const linesDisplay = drawLines(
      seasonLines, greyColor, xScale, seasonYscale, indicatorKey,
    );
    const shapesDisplay = drawShapes(
      seasonData, greyColor,
      xScale, seasonYscale, configChartsPoint,
      useValueFormatingonShapeText, indicatorValueFormating,
      isPrint,
      indicatorKey,
    );

    return ({
      seasonLabel,
      linesDisplay,
      shapesDisplay,
    });
  });

  return (
    <Box className="chart" sx={{ mt: isPrint ? 0 : 1, mb: isPrint ? 0 : 2 }}>
      {isSingleSeasonRow && displaySeasons && (
        <Typography variant="body1" align="center" sx={{ fontWeight: 'bold', fontSize: 'h6.fontSize' }}>{displaySeasons}</Typography>
      )}
      <svg height={`${height}px`} width={`${width}px`}>
        {drawingData.map(({ seasonLabel, linesDisplay, shapesDisplay }) => {
          const shouldNotDisplaySeason = isSingleSeasonRow && (seasonLabel !== 'annuel') && (seasonLabel !== selectedSeason);
          const dataValues = formatedData.find(({ label, type_ind: typeInd }) => (!shouldNotDisplaySeason && label === seasonLabel) || typeInd === 'annuel');

          const tooltipContent = (
            <TooltipContent
              title={title}
              indicatorKey={indicatorKey}
              indicatorValueFormating={indicatorValueFormating}
              indicatorTooltipTitle={indicatorTooltipTitle}
              seasonLabel={seasonLabel}
              reference={dataValues?.ref}
              min={dataValues?.min}
              mean={dataValues?.mean}
              max={dataValues?.max}
            />
          );

          return !shouldNotDisplaySeason && (
            <HtmlTooltip title={tooltipContent} key={`tooltip-${indicatorKey}-${seasonLabel}`}>
              <g key={`group-${indicatorKey}-${seasonLabel}`}>
                {linesDisplay}
                {shapesDisplay}
              </g>
            </HtmlTooltip>
          );
        })}
        {!isSingleSeasonRow && displaySeasons.map(({ position, label }) => svgText(
          { x: 'label', y: 10, text: label, season: position, xScale, yScale: x => x, textSize: isPrint ? seasonTextPxSizePrint : 17, fill: '#003661' },
        ))}
      </svg>
    </Box>
  );
};

export default Chart;
