import * as React from 'react';
import { scaleLinear, scaleBand, scalePoint } from 'd3-scale';
import { indicatorsConfiguration } from './settings';

export const entityDataExample = [
  { type_ind: 'annuel', label: null, ref: 5, min: 6, mean: 10, max: 11 },
  /*
  { type_ind: 'saisonnier', label: 'Été', ref: 8, min: 11, mean: 13, max: 14 },
  { type_ind: 'saisonnier', label: 'Automne', ref: 1, min: 2, mean: 3, max: 7 },
  { type_ind: 'saisonnier', label: 'Hiver', ref: 3, min: 2, mean: 5, max: 6 },
  { type_ind: 'saisonnier', label: 'Printemps', ref: 9, min: null, mean: 13, max: null },
  */
];

export const getSeasons = data => data.map(({ label, type_ind: type }) => label || type).flat();

export const xPositions = ['ref', 'label', 'target'];

export const circleSizeConfig = {
  annual: 30,
  season: 23,
};
const lineWidthConfig = {
  annual: 2,
  season: 1,
};
const textSizeConfig = {
  annual: 19,
  season: 15,
};

export const getIndicatorColorPalette = (indicatorKey = 'G1') =>
  indicatorsConfiguration[indicatorKey] && indicatorsConfiguration[indicatorKey]?.color;

export const getIndicatorValueFormating = (indicatorKey = 'G1') =>
  indicatorsConfiguration[indicatorKey] && indicatorsConfiguration[indicatorKey]?.valueRounding;

export const getTooltipTitle = (indicatorKey = 'G1') =>
  indicatorsConfiguration[indicatorKey] && indicatorsConfiguration[indicatorKey]?.tooltipTitle;

export const buildConfigChartPoint = ({
  ref = '#B6B6B6',
  min = '#96D2FA',
  mean = '#6496FA',
  max = '#0A46AF',
}) => ({
  ref: { color: ref, shape: 'circle' },
  min: { color: min, shape: 'circle' },
  mean: { color: mean, shape: 'circle' },
  max: { color: max, shape: 'circle' },
});

export const getLegends = (config, legendItems = []) => legendItems.map(item => ({ ...item, color: config[item.type]?.color || 'grey' }));

export const getLinearScale = (range, domain) => scaleLinear()
  .range(range)
  .domain(domain);

export const getPointScale = (range, domain, padding = 0.1, round = true) => scalePoint()
  .domain(domain)
  .range(range)
  .padding(padding)
  .round(round);

export const getBandScale = (range, domain, paddingInner = 0.4, paddingOuter = 0.1) => scaleBand()
  .domain(domain)
  .range(range)
  .paddingInner(paddingInner)
  .paddingOuter(paddingOuter);

const circleShape = ({
  x, value, season, xScale, yScale, color, r, indicatorKey,
}) => (
  <circle
    key={`circle-${indicatorKey}-${x}-${value}-${season}`}
    cx={xScale(x, season)}
    cy={yScale(value)}
    r={r}
    fill={color}
  />
);

const line = ({
  xStart, yStart, xEnd, yEnd, xScale, yScale, axisSeason, color, indicatorKey,
}) => {
  const lineWidth = ['singleAxis', 'annuel'].includes(axisSeason) ? lineWidthConfig.annual : lineWidthConfig.season;
  return (
    <line
      key={`chart-line-${indicatorKey}-${xStart}-${yStart}-${xEnd}-${yEnd}-${color}`}
      x1={xScale(xStart, axisSeason)}
      y1={yScale(yStart)}
      x2={xScale(xEnd, axisSeason)}
      y2={yScale(yEnd)}
      stroke={color}
      strokeWidth={lineWidth}
    />
  );
};

export const svgText = ({ x, y, text, season, xScale, yScale, textSize = 15, textyOffset = 0, fill = 'white' }) => (
  <text
    key={`text-${x}-${y}-${text}-${season}`}
    x={xScale(x, season)}
    y={yScale(y) + textyOffset}
    fontSize={textSize}
    textAnchor="middle"
    dominantBaseline="central"
    fill={fill}
    fontWeight="bold"
  >
    {text}
  </text>
);

export const drawLines = (dataLines = [], greyColor = 'grey', xScale, yScale, indicatorKey) =>
  dataLines.map(({ xStart, yStart, xEnd, yEnd, color, lineWidth, axisSeason }) => {
    const lineColor = color || greyColor;
    return line({
      xStart,
      yStart,
      xEnd,
      yEnd,
      xScale,
      yScale,
      axisSeason,
      color: lineColor,
      lineWidth,
      indicatorKey,
    });
  });

const getTextConfig = (type = 'ref', shapeColor = 'white', offsetPx = 15) => {
  let textOffset = 0;
  let textColor = 'white';
  if (type === 'min') {
    textOffset = offsetPx;
    textColor = shapeColor;
  }
  if (type === 'max') {
    textOffset = -offsetPx;
    textColor = shapeColor;
  }

  return { textOffset, textColor };
};

export const drawShapes = (
  dataShapes = [],
  greyColor = 'grey',
  xScale,
  yScale,
  config,
  shouldFormatText = false,
  textFormating = value => value,
  isPrint = false,
  indicatorKey,
) =>
  dataShapes.map(({ x, type, value, axisSeason }) => {
    const shapeColor = config[type]?.color || greyColor;
    const annualSize = !isPrint && ['singleAxis', 'annuel'].includes(axisSeason);
    const shapeRadius = annualSize ? circleSizeConfig.annual : circleSizeConfig.season;
    const textSize = annualSize ? textSizeConfig.annual : textSizeConfig.season;
    const { textOffset, textColor } = getTextConfig(type, shapeColor, shapeRadius);

    return (
      <React.Fragment key={`${x}${type}${value}${axisSeason}`}>
        {circleShape({
          x,
          value,
          season: axisSeason,
          xScale,
          yScale,
          color: shapeColor,
          r: shapeRadius,
          indicatorKey,
        })}
        {svgText({
          x,
          y: value,
          text: shouldFormatText ? textFormating(value) : value,
          season: axisSeason,
          xScale,
          yScale,
          textSize,
          textyOffset: textOffset * 1.4,
          fill: textColor,
        })}
      </React.Fragment>
    );
  });
