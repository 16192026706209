import React from 'react';
import { Stack, Typography } from '@mui/material';

import { printTextSize } from '../entityPageHelpers';

const IndicatorTopo = ({
  entity: {
    port,
    massif,
    massifMin,
    massifMax,
  } = {},
  indicatorKey,
}) => (
  <>
    {indicatorKey === 'R3' && port && (
      <Typography sx={{ ...printTextSize, mb: 2 }}>
        Port de référence : <b>{port}</b>
      </Typography>
    )}

    {(indicatorKey === 'T2' || indicatorKey === 'T3') && massif && (
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography sx={{ ...printTextSize }}>
          Massif : <b>{massif}</b>
        </Typography>
        {indicatorKey === 'T2' && (
          <Typography sx={{ ...printTextSize }}>
            Altitude basse : <b>Autour de {massifMin} mètres</b>
          </Typography>
        )}
        {indicatorKey === 'T3' && (
          <Typography sx={{ ...printTextSize }}>
            Altitude haute : <b>Autour de {massifMax} mètres</b>
          </Typography>
        )}
      </Stack>
    )}
  </>
);

export default IndicatorTopo;
