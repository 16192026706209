import React from 'react';
import { Stack, Typography } from '@mui/material';

import useTokenReplacement from '../hooks/useTokenReplacement';

const LegendItem = ({ label, description, color }) => {
  const replaceTokens = useTokenReplacement();

  return (
    <Stack
      flexBasis={0}
      flexGrow={1}
      direction="column"
      alignItems={description ? 'flex-start' : 'center'}
      key={label}
    >
      <Stack
        spacing={1}
        alignItems="center"
        direction="row"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        width={description ? 'unset' : '175px'}
      >
        <svg width={16} height={16}>
          <circle cx="8" cy="8" r="8" fill={color} />
        </svg>
        <Typography
          variant={description ? 'body1' : 'body2'}
          sx={{ '@media print': { fontSize: '0.9em' } }}
        >
          {replaceTokens(label)}
        </Typography>
      </Stack>

      {description && (
        <Typography variant="body2" sx={{ '@media print': { fontSize: '0.8em' } }}>{replaceTokens(description)}</Typography>
      )}
    </Stack>
  );
};

export default React.memo(LegendItem);
