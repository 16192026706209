import React from 'react';
import { useSelector } from 'react-redux';
import IndicatorDescriptionParagraph from './IndicatorDescriptionParagraph';

const IndicatorDescription = ({
  description: outlookDescriptions = [],
  ...props
}) => {
  const outlookIndex = useSelector(state => state.outlook.index);

  return (
    <IndicatorDescriptionParagraph
      description={outlookDescriptions[outlookIndex]}
      {...props}
    />
  );
};

export default React.memo(IndicatorDescription);
