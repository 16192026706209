import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import { Description } from '@mui/icons-material';

import PrintContext from '../hooks/printContext';
import { printPage } from './PrintComponents';

const PrintButton = ({ code, nom }) => {
  const { openPrintDialog } = React.useContext(PrintContext);
  const outlook = useSelector(state => state.outlook.value);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => printPage({ code, name: nom, outlook, showPrint: openPrintDialog })}
        endIcon={<Description />}
        sx={{ borderRadius: 15, width: 240 }}
      >
        Télécharger la synthèse
      </Button>
    </Box>
  );
};

export default React.memo(PrintButton);
