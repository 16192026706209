import React from 'react';
import { Box } from '@mui/material';
import EntityContentTitle from './EntityContentTitle';
import Indicator from './Indicator';

const IndicatorGroup = ({
  entity = {},
  itemsState,
  screenType,
  groupTitle,
  items = [],
  anchor,
}) => {
  if (items.every(item => !itemsState[item.key])) {
    // All indicators for current entity are hidden : do not render group
    return null;
  }

  return ( // Indicator group
    <Box sx={{ pageBreakInside: 'unset' }}>
      <EntityContentTitle title={groupTitle} type={entity.type} id={anchor} />

      {items.map(({
        contentTitle,
        key: indicatorKey,
        description,
      }) => {
        const display = itemsState[indicatorKey];

        return (
          <Indicator
            entity={entity}
            key={indicatorKey}
            indicatorKey={indicatorKey}
            display={display}
            title={contentTitle}
            screenType={screenType}
            description={description}
          />
        );
      })}
    </Box>
  );
};

export default IndicatorGroup;
