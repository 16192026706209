import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ChartContainer from './ChartContainer';

import { PageBreak, printPage } from './PrintComponents';
import ReadingGuideReadMore from './ReadingGuideReadMore';

import PrintContext from '../hooks/printContext';
import { entityDataExample } from '../chartsHelpers';
import { formattedEntity } from '../entityPageHelpers';

const exampleIndicatorKey = 'R4';

const ReadingGuide = ({ entityType = 'commune', code, name }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { openPrintDialog } = React.useContext(PrintContext);
  const [isOpen, setIsOpen] = useState(false);
  const outlook = useSelector(state => state.outlook.value);

  return (
    <>
      <Typography id="pourquoi" sx={{ marginTop: '-50px', paddingTop: '50px' }} variant="h5">Pourquoi Climadiag Commune ?</Typography>

      <Typography gutterBottom>
        L’urgence climatique est là. Pour agir, il est indispensable de
        connaître avec précision les évolutions climatiques auxquelles il faut
        s’adapter. La trajectoire de réchauffement de référence pour
        l’adaptation au changement climatique (TRACC), mise en place par le
        Ministère de la Transition Ecologique et de la Cohésion des Territoires,
        en est le fil conducteur : la France doit être en mesure de s’adapter à
        un réchauffement, par rapport à l’ère pré-industrielle, de +2.0 °C d’ici
        2030, de +2.7 °C d’ici 2050 et de +4.0 °C d’ici la fin du siècle.
      </Typography>

      <Typography gutterBottom>
        Pour chacun de ces trois niveaux de réchauffement et donc pour chacun
        des trois horizons temporels correspondants, Climadiag Commune propose
        une synthèse des informations indispensables à l’adaptation via une
        liste d’indicateurs climatiques ciblés pour votre {formattedEntity[entityType]}.
      </Typography>

      <Typography gutterBottom sx={{ displayPrint: 'none' }}>
        Pour l’horizon choisi, vous pouvez d’ores et
        déjà <Link href="#" fontWeight={600} onClick={() => printPage({ code, name, outlook, showPrint: openPrintDialog })}>télécharger la synthèse</Link> au
        format pdf ou sélectionner préalablement des indicateurs
        complémentaires, au moyen de la barre de navigation à droite.
      </Typography>

      <Accordion expanded={isOpen} onChange={() => setIsOpen(!isOpen)} elevation={0} sx={{ displayPrint: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight={600} color="primary">{isOpen ? 'Refermer les détails' : 'Lire la suite'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReadingGuideReadMore />
        </AccordionDetails>
      </Accordion>
      <Box sx={{ display: 'none', displayPrint: 'block' }}>
        <ReadingGuideReadMore />
        <PageBreak />
      </Box>

      <Typography variant="h5" sx={{ marginTop: '-50px', paddingTop: '50px', '@media print': { padding: 0, marginTop: 'unset' } }} id="figures">Comprendre les infographies</Typography>

      <Typography gutterBottom>
        La synthèse mise à votre disposition a été élaborée à partir d’un
        ensemble de projections climatiques régionales, ce qui permet de décrire
        le champ des possibles quant à l’évolution de chaque indicateur, en
        encadrant la valeur médiane attendue autour de l’horizon temporel retenu
        par une fourchette correspondant à un intervalle de confiance.
      </Typography>

      <Typography gutterBottom>
        Chaque indicateur est présenté sous forme d’une infographie résumant de
        façon synthétique son évolution : quatre valeurs de l’indicateur sont
        presque systématiquement présentées :
      </Typography>

      <ul>
        <li>La valeur pour la période de référence (1976-2005)</li>
        <li>Pour L’horizon temporel retenu :</li>
        <ul>
          <li>La valeur médiane attendue</li>
          <li>
            Les deux bornes inférieure et supérieure de l’intervalle de
            confiance à 90% pour la plupart des indicateurs
          </li>
        </ul>
      </ul>

      <Box sx={{ displayPrint: 'none' }}>
        <ChartContainer
          indicatorKey={exampleIndicatorKey}
          data={entityDataExample}
          showLegendDescription
          showLegendHowToRead={false}
          isSingleSeasonRow={isSmallScreen}
        />
      </Box>

      <Box sx={{ display: 'none', displayPrint: 'block' }}>
        <ChartContainer
          indicatorKey={exampleIndicatorKey}
          data={entityDataExample}
          showLegendDescription
          showLegendHowToRead={false}
          isSingleSeasonRow={isSmallScreen}
          isPrint
        />
      </Box>

      <Typography gutterBottom>
        En fonction de l’indicateur choisi, on propose la valeur pour l’ensemble
        de l’année ou pour chaque saison.
      </Typography>
      <PageBreak />
    </>
  );
};

export default ReadingGuide;
