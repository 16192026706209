import React from 'react';

import { Typography } from '@mui/material';
import useTokenReplacement from '../hooks/useTokenReplacement';

const EntityContentTitle = ({
  title = '',
  type,
  sx = {},
  ...props
}) => {
  const replaceTokens = useTokenReplacement({ entityType: type });

  return (
    <Typography
      variant="h3"
      sx={{
        marginTop: '-50px',
        paddingTop: '80px',
        '@media print': {
          paddingTop: 0,
          marginTop: '5px',
          marginBottom: '5px',
          fontSize: '1.5em',
        },
        pageBreakBefore: 'always',
        ...sx,
      }}
      {...props}
    >
      {replaceTokens(title)}
    </Typography>
  );
};

export default EntityContentTitle;
