import React from 'react';
import { Typography } from '@mui/material';
import { risksText } from '../settings';
import { printTextSize } from '../entityPageHelpers';
import useTokenReplacement from '../hooks/useTokenReplacement';

const Risks = ({
  entity: {
    type,
    risques: risks = [],
    icu,
  } = {},
  indicatorKey,
}) => {
  const replaceTokens = useTokenReplacement({ icu });

  if ((indicatorKey === 'R1' || indicatorKey === 'R2') && risks.includes('Inondations')) {
    return type === 'commune'
      ? <Typography sx={{ ...printTextSize, fontWeight: '600' }}>{replaceTokens(risksText.inondations.commune)}</Typography>
      : <Typography sx={{ ...printTextSize, fontWeight: '600' }}>{replaceTokens(risksText.inondations.epci)}</Typography>;
  }
  if (indicatorKey === 'R3' && risks.includes('Submersion et érosion côtière')) {
    return type === 'commune'
      ? <Typography sx={{ ...printTextSize, fontWeight: '600' }}>{replaceTokens(risksText.submersion.commune)}</Typography>
      : <Typography sx={{ ...printTextSize, fontWeight: '600' }}>{replaceTokens(risksText.submersion.epci)}</Typography>;
  }
  if (indicatorKey === 'R4' && risks.includes('Feu de forêt')) {
    return type === 'commune'
      ? <Typography sx={{ ...printTextSize, fontWeight: '600' }}>{replaceTokens(risksText.feu.commune)}</Typography>
      : <Typography sx={{ ...printTextSize, fontWeight: '600' }}>{replaceTokens(risksText.feu.epci)}</Typography>;
  }
  if (indicatorKey === 'R5' && risks.includes('Retrait-Gonflement des argiles')) {
    return type === 'commune'
      ? <Typography sx={{ ...printTextSize, fontWeight: '600' }}>{replaceTokens(risksText.retrait.commune)}</Typography>
      : <Typography sx={{ ...printTextSize, fontWeight: '600' }}>{replaceTokens(risksText.retrait.epci)}</Typography>;
  }
  if ((indicatorKey === 'S1' || indicatorKey === 'S2' || indicatorKey === 'S3') && risks.includes('Ilot de chaleur urbain')) {
    return type === 'commune'
      ? <Typography sx={{ ...printTextSize, fontWeight: '600' }}>{replaceTokens(risksText.ilot.commune)}</Typography>
      : <Typography sx={{ ...printTextSize, fontWeight: '600' }}>{replaceTokens(risksText.ilot.epci)}</Typography>;
  }
  return null;
};

export default React.memo(Risks);
