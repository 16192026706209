import React from 'react';

import {
  Box,
  Typography,
  Divider,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'gatsby';

import { useTheme } from '@emotion/react';
import { chartLegend } from '../settings';
import LegendItem from './LegendItem';

const ChartLegend = ({
  legendItems = [],
  showDescription = false,
  showHowToRead = true,
}) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Stack
        sx={{
          flexDirection: isSmallScreen || isMediumScreen ? 'column' : 'row',
          gap: 2,
          '@media print': {
            flexDirection: showDescription ? 'column' : 'row',
            gap: 1,
          },
        }}
      >
        <Stack
          sx={{
            flexDirection: isVerySmallScreen ? 'column' : 'row',
            gap: isVerySmallScreen ? 2 : 4,
            '@media print': {
              flexDirection: 'row',
              gap: 4,
            },
          }}
          justifyContent="space-around"
          flexGrow={1}
        >
          {legendItems.slice(0, 2).map(legendItem => (
            <LegendItem
              key={legendItem.description}
              {...legendItem}
              description={showDescription && legendItem.description}
            />
          ))}
        </Stack>
        <Stack
          sx={{
            flexDirection: isVerySmallScreen ? 'column' : 'row',
            gap: isVerySmallScreen ? 2 : 4,
            '@media print': {
              flexDirection: 'row',
              gap: 4,
            },
          }}
          justifyContent="space-around"
          flexGrow={1}
        >
          {legendItems.slice(2).map(legendItem => (
            <LegendItem
              key={legendItem.description}
              {...legendItem}
              description={showDescription && legendItem.description}
            />
          ))}
        </Stack>
      </Stack>
      <Divider sx={{ my: 2, '@media print': { my: 1 } }} />

      {showHowToRead && (
        <Box sx={{ mb: 1, displayPrint: 'none', textAlign: 'center' }}>
          <Link to="#figures">
            <Typography variant="caption">{chartLegend.linkToHowToReadText}</Typography>
          </Link>
        </Box>
      )}
    </>
  );
};
export default ChartLegend;
