import { Box, Link, Typography } from '@mui/material';
import React from 'react';

const ReadingGuideReadMode = () => (
  <>
    <Typography gutterBottom>
      Les indicateurs sont calculés à partir de projections climatiques de
      référence sur la métropole (DRIAS2020-EXPLORE2).
    </Typography>
    <Typography gutterBottom>
      Les indicateurs climatiques sont organisés en cinq familles :
    </Typography>
    <ul>
      <li>Climat</li>
      <Box sx={{ marginBottom: 2 }}>
        Quatre indicateurs météorologiques généraux susceptibles d’intéresser
        toutes les communes (température moyenne, jours de gel, cumul de
        pluie, jours avec pluie) ;
      </Box>
      <li>Risques naturels</li>
      <Box sx={{ marginBottom: 2 }}>
        Cinq indicateurs concernant les risques naturels liés à des événements
        intenses (jours avec pluies intenses, pluie exceptionnelle, sécheresse
        du sol, risque de feu de forêt, niveau de la mer) ;
      </Box>

      <Box sx={{ marginBottom: 2, fontStyle: 'italic' }}>
        Note : L’indicateur présenté pour le niveau de la mer émane de travaux
        du GIEC pour des horizons et niveaux de réchauffement proches de ceux de
        la TRACC. Des travaux sont actuellement menés par le BRGM pour
        documenter l’évolution des niveaux de la mer selon la TRACC. Les
        résultats seront intégrés dans Climadiag Commune dès leur disponibilité.
      </Box>

      <li>Santé</li>
      <Box sx={{ marginBottom: 2 }}>
        Quatre indicateurs concernant des risques spécifiques pour la santé
        (jours très chauds, nuits chaudes, vagues de chaleur, vagues de froid)
        ;
      </Box>
      <li>Agriculture</li>
      <Box sx={{ marginBottom: 2 }}>
        Quatre indicateurs concernant l’agriculture (jours consécutifs sans
        pluie, reprise de la végétation, disponibilité thermique pour le blé,
        jours échaudants) ;
      </Box>
      <li>Tourisme</li>
      <Box sx={{ marginBottom: 2 }}>
        Trois indicateurs concernant le tourisme (jours estivaux, enneigement
        à basse altitude, enneigement à haute altitude) ;
      </Box>
    </ul>
    <Typography gutterBottom>
      Les indicateurs systématiquement proposés (cochés en bleu) peuvent être
      complétés en sélectionnant un ou plusieurs indicateurs complémentaires (en vert).
    </Typography>
    <Typography gutterBottom>
      Les vulnérabilités vis-à-vis de certains risques naturels identifiés
      aujourd'hui par le service Géorisques (<Link href="https://www.georisques.gouv.fr">www.georisques.gouv.fr</Link>)
      sont rappelées dans la rubrique "Caractéristiques de la commune" ainsi
      qu’en regard des indicateurs climatiques correspondants (précipitations
      fortes pour le risque inondations... etc).
    </Typography>
  </>
);

export default React.memo(ReadingGuideReadMode);
