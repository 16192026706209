import React, { useState, useEffect } from 'react';

import { Grid, IconButton, Stack } from '@mui/material';
import { ArrowBackIos as ArrowBack, ArrowForwardIos as ArrowForward } from '@mui/icons-material';

import Chart from './Chart';
import ChartLegend from './ChartLegend';

import {
  getLegends, getIndicatorColorPalette, buildConfigChartPoint,
  entityDataExample,
} from '../chartsHelpers';

import { chartLegend } from '../settings';

const chartSize = {
  largeScreen: {
    season: {
      width: 680,
      height: 300,
    },
    annual: {
      width: 300,
      height: 250,
    },
  },
  print: {
    season: {
      width: 600,
      height: 230,
    },
    annual: {
      width: 250,
      height: 200,
    },
  },
  mediumScreen: {
    season: {
      width: 500,
      height: 300,
    },
    annual: {
      width: 300,
      height: 250,
    },
  },
  smallScreen: {
    season: {
      width: 230,
      height: 280,
    },
    annual: {
      width: 230,
      height: 280,
    },
  },
};

const getChartSize = (screenType = 'largeScreen', isSeason = false, isPrint = false) => {
  const seasonType = isSeason ? 'season' : 'annual';

  return isPrint
    ? chartSize.print[seasonType]
    : chartSize[screenType][seasonType] || { width: 200, height: 350 };
};

const getPreviousArrayItem = (array = [], currentItem = '') => {
  const currentItemIndex = array.findIndex(el => el === currentItem);
  if (currentItemIndex === -1) { return null; }
  const previousIndex = currentItemIndex - 1 >= 0 ? currentItemIndex - 1 : array.length - 1;
  return array[previousIndex];
};

const getNextArrayItem = (array = [], currentItem = '') => {
  const currentItemIndex = array.findIndex(el => el === currentItem);
  if (currentItemIndex === -1) { return null; }
  const nextIndex = currentItemIndex + 1 < array.length ? currentItemIndex + 1 : 0;
  return array[nextIndex];
};

const ChartContainer = ({
  isSingleSeasonRow = false,
  entityData = entityDataExample,
  seasons = [],
  selectedSeason = '',
  indicatorKey = 'G1',
  showLegendDescription = false,
  showLegendHowToRead = true,
  title = '',
  screenType = 'largeScreen',
  isPrint = false,
}) => {
  const canSwitchSeason = Boolean(isSingleSeasonRow && selectedSeason);
  const hasSeasons = seasons.length > 1;
  const { width, height } = getChartSize(screenType, hasSeasons, isPrint);

  const [currentSeason, setCurrentSeason] = useState(selectedSeason);
  useEffect(() => {
    setCurrentSeason(selectedSeason);
  }, [selectedSeason]);

  const indicatorColorPalette = getIndicatorColorPalette(indicatorKey);
  const configChartsPoint = buildConfigChartPoint(indicatorColorPalette);
  const legends = getLegends(configChartsPoint, chartLegend.legendItems);

  return (
    <Stack direction="column">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: isPrint ? 0 : 2, mb: isPrint ? 0 : 1 }}
      >
        {canSwitchSeason && (
          <IconButton
            aria-label="previous"
            onClick={() => setCurrentSeason(getPreviousArrayItem(seasons, currentSeason))}
            sx={{ p: 0 }}
          >
            <ArrowBack />
          </IconButton>
        )}
        <Chart
          indicatorKey={indicatorKey}
          configChartsPoint={configChartsPoint}
          data={entityData}
          selectedSeason={currentSeason}
          isSingleSeasonRow={isSingleSeasonRow}
          width={width}
          height={height}
          title={title}
          isPrint={isPrint}
          screenType={screenType}
        />
        {canSwitchSeason && (
          <IconButton
            aria-label="next"
            onClick={() => setCurrentSeason(getNextArrayItem(seasons, currentSeason))}
            sx={{ p: 0 }}
          >
            <ArrowForward />
          </IconButton>
        )}
      </Grid>
      <ChartLegend
        legendItems={legends}
        showDescription={showLegendDescription}
        showHowToRead={showLegendHowToRead}
      />
    </Stack>
  );
};

export default ChartContainer;
