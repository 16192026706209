import React from 'react';
import QRCodeGenerator from 'qrcode';
import { Box } from '@mui/material';

const QRCode = ({ link, sx = {}, ...props }) => {
  const [dataUrl, setDataUrl] = React.useState();

  const generateCode = React.useCallback(
    async url => {
      const result = await QRCodeGenerator.toDataURL(
        url,
        {
          errorCorrectionLevel: 'low',
          margin: 0,
          width: sx.width * 4 || 100,
          color: {
            dark: '#02568d',
          },
        },
      );
      setDataUrl(result);
    },
    [sx.width],
  );

  React.useEffect(
    () => { generateCode(link); },
    [generateCode, link],
  );

  return (
    <Box
      component="img"
      src={dataUrl}
      {...sx}
      {...props}
    />
  );
};

export default React.memo(QRCode);
