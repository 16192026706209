import React from 'react';

import { Box, List, ListItem, Typography } from '@mui/material';
import { characteristicsText } from '../settings';

// TO DO
// create object in settings to configure this part

const EntityCharacteristics = ({
  entity: {
    nom,
    code,
    population,
    departement,
    risques,
    altMin,
    altMax,
    type,
  } = {},
  ...props
}) => (
  <List {...props}>
    <ListItem>
      {nom && (
      <Typography gutterBottom textTransform="capitalize" component="div">{type} :{' '}
        <Box fontWeight="fontWeightMedium" component="span">{nom} ({code})</Box>
      </Typography>
      )}
    </ListItem>
    <ListItem>
      {nom && (
      <Typography gutterBottom component="div">{characteristicsText.departement} :{' '}
        <Box fontWeight="fontWeightMedium" component="span">{departement}</Box>
      </Typography>
      )}
    </ListItem>
    <ListItem>
      {nom && (
      <Typography gutterBottom component="div">{characteristicsText.altMin} :{' '}
        <Box fontWeight="fontWeightMedium" component="span">{altMin} m</Box>
      </Typography>
      )}
    </ListItem>
    <ListItem>
      {nom && (
      <Typography gutterBottom component="div">{characteristicsText.altMax} :{' '}
        <Box fontWeight="fontWeightMedium" component="span">{altMax} m</Box>
      </Typography>
      )}
    </ListItem>
    <ListItem>
      {population && (
      <Typography gutterBottom component="div">{characteristicsText.population} :{' '}
        <Box fontWeight="fontWeightMedium" component="span">{`${population} ${characteristicsText.habitants}`}</Box>
      </Typography>
      )}
    </ListItem>
    <ListItem>
      {risques?.length > 0 && (
      <Typography gutterBottom component="div">{characteristicsText.risques} :{' '}
        <Box fontWeight="fontWeightMedium" component="span">{risques.filter(e => e !== 'Ilot de chaleur urbain').join(', ')}</Box>
      </Typography>
      )}
    </ListItem>
    <ListItem>
      {risques?.includes('Ilot de chaleur urbain') && (
      <Typography gutterBottom component="div">{characteristicsText.autreEnjeu} :{' '}
        <Box fontWeight="fontWeightMedium" component="span">{characteristicsText.ilot}</Box>
      </Typography>
      )}
    </ListItem>
    {/* <ListItem>
      {epci && epciId && (
      <Link to={`/entity/${epciId}`}>
        <Typography gutterBottom>{`EPCI : ${epci}`}</Typography>
      </Link>
      )}
    </ListItem> */}
  </List>
);

export default EntityCharacteristics;
