const { contentHierarchy, indicatorsConfiguration } = require('./settings');

const arrayToObject = (array = [], key = 'key', value = true) =>
  array.reduce((acc, arrayItem) => ({ ...acc, [arrayItem[key]]: value }), {});

const allItems = contentHierarchy.map(({ items }) => items).flat();
const indicatorsSelectedState = arrayToObject(allItems);

const getEntityStatuses = (entity, indicatorList = indicatorsConfiguration) => {
  const entityIndicatorStatuses = {};
  Object.entries(indicatorList).forEach(([key, value]) => {
    entityIndicatorStatuses[key] = value?.preselect(entity);
  });
  return entityIndicatorStatuses;
};

const getIndicatorsWithStatus = (entity, allIndicators = Object.keys(indicatorsConfiguration)) => {
  const entityStatuses = getEntityStatuses(entity);
  // filter out indicators not to be displayed
  const indicators = allIndicators.filter(indicator => entityStatuses[indicator] !== null);

  // convert to object with status
  return indicators.reduce(
    (acc, arrayItem) => ({ ...acc, [arrayItem]: entityStatuses[arrayItem] }),
    {},
  );
};

const getIndicatorData = (indicators = [], indicatorId = 'G1') => indicators.find(({ id }) => id === indicatorId)?.data;

const capitalizeFirstLetter = text => (text.length > 1
  ? text.charAt(0).toUpperCase() + text.slice(1)
  : text);

const getSelectedSeason = (seasons, isSmallScreen) => {
  let selectedSeason = '';
  const hasSelectedSeason = seasons && isSmallScreen && seasons[0] !== 'annuel';
  const summerIndex = hasSelectedSeason ? seasons.findIndex(s => s === 'été') : null;
  if (hasSelectedSeason && summerIndex) { selectedSeason = seasons[summerIndex]; }
  if (hasSelectedSeason && !summerIndex) { [selectedSeason] = seasons; }
  return selectedSeason;
};

// to be used by a plugin gatsby-node.js
module.exports = {
  getIndicatorsWithStatus,
  arrayToObject,
  indicatorsSelectedState,
  getIndicatorData,
  capitalizeFirstLetter,
  getSelectedSeason,
};
